<template>
    <div>
        <ul class="dp_flex skeltonmyspace flex_space_between" v-for="(item, index) in skeltonList" :key="'dummy' + index">
            <li class="flex_justify_center">
                <ul class="dp_flex">
                    <li>
                        <vue-skeleton-loader
                            type="rect"
                            :rounded="true"
                            radius="8"
                            :width="200"
                            :height="200"
                            animation="wave"
                            :color="imgSkeltonColor"
                            :wave-color="imgSkeltonWaveColor"
                            class="skelton_space_img_wrap"
                        />
                    </li>
                    <li>
                        <vue-skeleton-loader
                            type="rect"
                            :rounded="true"
                            radius="4"
                            :width="388"
                            :height="200"
                            animation="wave"
                            :color="contentsSkeltonColor"
                            :wave-color="contentsSkeltonWaveColor"
                            class="skelton_space_contents"
                        />
                    </li>
                </ul>
            </li>
            <li class="flex_justify_center">
                <ul class="dp_flex">
                    <li>
                        <vue-skeleton-loader
                            type="rect"
                            :rounded="true"
                            radius="8"
                            :width="200"
                            :height="200"
                            animation="wave"
                            :color="imgSkeltonColor"
                            :wave-color="imgSkeltonWaveColor"
                            class="skelton_space_img_wrap"
                        />
                    </li>
                    <li>
                        <vue-skeleton-loader
                            type="rect"
                            :rounded="true"
                            radius="4"
                            :width="388"
                            :height="200"
                            animation="wave"
                            :color="contentsSkeltonColor"
                            :wave-color="contentsSkeltonWaveColor"
                            class="skelton_space_contents"
                        />
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
  name: 'SkeltonMySpace',
  data () {
    return {
      imgSkeltonColor: 'rgba(59, 59, 59, 1)',
      imgSkeltonWaveColor: 'rgba(89, 89, 89, 1)',
      contentsSkeltonColor: 'rgba(38, 38, 38, 1)',
      contentsSkeltonWaveColor: 'rgba(38, 38, 38, 1)',
      skeltonList: ['skelton1', 'skelton2', 'skelton3']
    };
  }
};
</script>
<style scoped src="@/assets/css/myspace/skeltonmyspace.css"></style>
