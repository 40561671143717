<template>
    <div class="single-file-alert alert">
        <popup-modal v-if="isPopUpModal" :text="popupModalText" @close-modal="closeModal" />
        <div class="single-file-box alert_box">
            <h3 v-if="spaceNm != null" v-html="spaceNm + '의 스페이스를 삭제하시겠습니까?'" />
            <h3 v-else>스페이스를 삭제하시겠습니까?</h3>
            <p class="single-file-content">
                스페이스를 삭제하면 해당 공간 정보가 삭제됩니다.<br>
                자동 큐레이터에 누적된 취향 음악이 삭제됩니다.
            </p>
            <div class="single-file-btn">
                <p @click="$emit('closeDelete')">아니오</p>
                <p id="color-text" @click="deleteSpace()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import PopUpModal from '@/components/modal/common/PopUpModal.vue';
import { defineComponent } from "@vue/composition-api";
import VueCookies from "vue-cookies";
import axios from "axios";

export default defineComponent({
  name: 'deleteSpaceModal',
  props: {
    spaceNm: String,
    spaceId: String
  },

  components: {
    'popup-modal': PopUpModal
  },

  data () {
    return {
      isPopUpModal: false
    };
  },

  methods: {
    deleteSpace () {
      var video = document.getElementById("video");
      var spaceId = this.spaceId;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/deleteMySpaceInfo", { spaceId }, { headers })
        .then(res => {
          // console.log(res.data.result);
          const cookieSpaceId = VueCookies.get('spaceId');
          if (spaceId === cookieSpaceId) {
            // 음악 멈춤
            video.pause();
            this.$store.commit("setPlay", false);
            // 쿠키 spaceId 삭제
            VueCookies.remove("spaceId");
            // vex spaceId, spaceNm 삭제
            this.$store.commit("setSpaceId", null);
            this.$store.commit("setSpaceNm", null);
          }
          this.$emit('getList');
          this.$emit('closeDelete');
        })
        .catch((err) => {
          console.log(err);
          const { data } = err.response;
          const { resultCd, resultMsg } = data;
          if (resultCd === "400" && resultMsg === "구독중인 스페이스는 삭제할 수 없습니다.") {
            this.popupModalText = resultMsg;
            this.isPopUpModal = true;
          }
        });
    },

    closeModal () {
      this.isPopUpModal = false;
      this.$emit('closeDelete');
      this.$emit('getList');
    }
  }
});
</script>
