<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 v-html="brandNm + ' 와(과)의 연결을 해제할까요?'" />
            <div class="single-file-btn">
                <p @click="$emit('closeDisconnect')">아니오</p>
                <p id="color-text" @click="getDisconnect()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";
import VueCookies from "vue-cookies";

export default defineComponent({
  name: 'Disconnect',
  props: {
    brandId: String,
    brandNm: String,
    spaceId: String
  },
  methods: {
    getDisconnect () {
      var brandId = this.brandId;
      var spaceId = this.spaceId;
      if (spaceId == null) {
        spaceId = localStorage.getItem("spaceId");
      }
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/requestBrandDisConnect", { brandId, spaceId }, { headers })
        .then((res) => {
          // console.log(res.data);
          this.$emit('closeDisconnect');
          this.$emit('getList');
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>
