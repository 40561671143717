<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>이어서 등록하시겠습니까?</h3>
            <div class="single-file-btn">
                <p @click="$emit('closeAddSpace');">아니오</p>
                <p id="color-text" @click="nextInsert()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import router from "@/router";

export default defineComponent({
  name: 'addSpace2',
  props: {
    spaceType: String,
    spaceId: String,
    spaceNm: String
  },
  methods: {
    nextInsert () {
      localStorage.setItem("spaceId", this.spaceId);
      this.$store.commit("coupon/setCuponInfo", null);
      this.$store.commit("setSpaceNm", null);
      if (this.spaceType === 'Store') {
        this.$emit('closeAddSpace');
        router.push({ name: "SpaceInsertStore", params: { spaceId: this.spaceId } });
      } else if (this.spaceType === 'Car') {
        this.$emit('closeAddSpace');
        router.push({ name: "SpaceInsertCar", params: { spaceId: this.spaceId } });
      } else {
        this.$emit('closeAddSpace');
        router.push({ name: "SpaceInsertBuilding", params: { spaceId: this.spaceId } });
      }
    }
  }
});
</script>
