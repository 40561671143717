<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>재구독 하시겠습니까?</h3>
            <div class="single-file-btn">
                <p @click="$emit('closeResubscribe')">아니오</p>
                <p id="color-text" @click="getResubscribe()">네</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueCookies from 'vue-cookies';
import axios from '@/service/axios';
import router from '@/router';

export default defineComponent({
  name: 'Resubscribe',
  props: {
    spaceType: String,
    spaceId: String,
    mode: String
  },
  methods: {
    getResubscribe () {
      if (this.spaceType === 'Building') {
        var spaceId = this.spaceId;
        var aToken = VueCookies.get('aToken');
        var headers = { 'X-AUTH-TOKEN': aToken };

        axios
          .post('/api/profile/updateCarPayAt', { spaceId }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              this.$emit('closeResubscribe');
              this.$emit('getList');
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.$gatag.event('resubscribe');
        localStorage.setItem('spaceId', this.spaceId);
        this.$emit('closeResubscribe');
        router.push({ name: 'SpacePayment' });
      }
    }
  }
});
</script>

<style scoped>
@media all and (max-width: 500px) {
  .add_space {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.8);
  }
  .add_space h3 {
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    font-weight: 300;
  }
  .card_popup_btn {
    width: 100%;
    height: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card_popup_btn p {
    color: #fff;
    font-size: 18px;
    line-height: 60px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
  }
  .card_popup_btn p#color_txt {
    color: #ff2d55;
  }
  .card_popup1 .card_popup_btn p {
    width: 50%;
  }
  .card_popup2 .card_popup_btn p {
    width: 100%;
  }
}
</style>
